<template>
  <div class="animated fadeIn container">
    <h1>
      请选择想充值的数量进行充值
    </h1>
    <h2 class="color-skyblue">
      건당 18원/ 한글, 영문 70자까지 입력
    </h2>
    <div class="row button-container mt-4">
      <div class="col-md-2 col-sm-2">
        信息充值
      </div>
      <div class="col-md-4 col-sm-3">
        <span style="font-size:20px">{{money | numberFormat}}</span>
        <span class="ml-1 ">条</span>
      </div>
      <div class="col col-sm-6 text-center">
        <ul class="button-list">
          <li :key="prefixMoney" class="btn-primary" v-for="prefixMoney in prefixMoneys" @click.prevent="money = prefixMoney">
            <span class="d-md-down-none">+</span> {{prefixMoney | numberFormat}}
            <span class="d-md-down-none">条</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2 col-sm-2"></div>
      <div class="col-md-8 col-sm-7">
        <span class="color-skyblue">
          <span class="font-weight-bold">{{availableSendCount | numberFormat}}</span>건 발송가능</span>
        <span class="color-flatwhite"> | 부가세 10% 포함 결제금액 {{taxedMoney | numberFormat}}원</span>
      </div>
    </div>

    <p>
      <a href="/deposit/history" class="history-link color-flatwhite">转到充值内容页面</a>
    </p>
    <b-btn variant="primary" class="mt-4 deposit-btn" @click.prevent="$router.push('/deposit/step2?money=' + money)">请求充值</b-btn>
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'DepositStep1',
    data() {
      return {
        money: 10000,
        prefixMoneys: [
          10000,
          30000,
          50000,
          100000,
          300000,
          500000,
          1000000,
          2000000
        ]
      }
    },
    mounted() {
      document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    },
    computed: {
      ...mapGetters({
        user: 'getUser'
      }),
      availableSendCount() {
        return parseInt(parseFloat(this.money) / 18)
      },
      taxedMoney() {
        return parseInt(parseFloat(this.money) * 1.1)
      }
    }
  }

</script>

<style scoped>
  .button-container {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;
  }

  .button-container>div {
    display: flex;
    align-items: center;
  }

  .button-list {
    list-style: none;
    width: 100%;
    line-height: 20px;
  }

  .button-list>li {
    float: left;
    color: white;
    position: relative;
    width: 23%;
    margin-left: 2%;
    padding: 4px 0;
    margin: 3px 1%;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    letter-spacing: -0.5px;
    height: 28px;
    overflow: auto;
    min-width: 70px;
  }

  .deposit-btn {
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
  }

  .history-link {
    text-decoration: underline;
    transition: color 0.3s ease-in-out;
  }

  .history-link:hover {
    color: #333
  }

</style>
